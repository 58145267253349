<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"/>

    <!-- filter -->
    <ARow :gutter="[8, 8]">
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-date-picker
          style="width:100%;"
          :disabled-date="disabledStartDate"
          v-model:value="state.params.start_date"
          placeholder=" Tanggal Awal"
          format="DD MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-date-picker
          style="width:100%;"
          :disabled-date="disabledEndDate"
          v-model:value="state.params.end_date"
          placeholder=" Tanggal Akhir"
          format="DD MMMM YYYY"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-regional
          style="width:100%;"
          v-model:value="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-province
          style="width:100%;"
          v-model:value="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-area
          style="width:100%;"
          v-model:value="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:region="state.params.region"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-kabupaten-kota
          style="width:100%;"
          v-model:value="state.params.kabupaten"
          v-model:region="state.params.region"
          v-model:provinsi="state.params.provinsi"
          v-model:area="state.params.area"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-brand
          style="width:100%;"
          v-model:value="state.params.brand"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24" v-can:hide.distributor>
        <filter-distributor
          style="width:100%;"
          v-model:value="state.params.distributor"
          v-model:distributor="state.params.distributor"
          v-model:region="state.params.region"
          v-model:area="state.params.area"
          v-model:provinsi="state.params.provinsi"
          v-model:kabupaten="state.params.kabupaten"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <filter-segment
          style="width:100%;"
          :mode="null"
          placeholder="Pilih Tipe Customer"
          v-model:value="state.params.type"/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-input-search
          v-model:value="state.params.q"
          placeholder="Cari ..."/>
      </ACol>
      <ACol :xl="6" :md="8" :sm="12" :span="24">
        <a-button
          type="primary"
          title="cari"
          @click="fetchData"
          :loading="state.isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!state.isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </ACol>
    </ARow>
    
    <div class="row justify-content-end">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <ASpace>
          <ATooltip title="Download Excel">
            <DownloadExcel
              :url="state.endpoint.current"
              :params="queryParams()"
              @errors="errorMessage"/>
          </ATooltip>
        </ASpace>
      </div>
    </div>

    <!-- table lists -->
    <div class="table-responsive mt-4">
      <md-table
        :columns="state.columns"
        :data-source="state.data"
        size="small"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: state.meta.total,
          pageSize: state.meta.per_page,
          current: state.meta.page,
        }"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        @change="handleTableChange"
        :loading="state.isFetching">

        <template #no="{ index }">
          <span>
            {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
          </span>
        </template>

        <template #nilai="{ text }">
          <span>{{ text ? new Intl.NumberFormat('id-ID').format(text) : 0 }}</span>
        </template>

        <template #erp_name="{text}">
          {{ text.erp_name }}
        </template>

      </md-table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterSegment from '@/components/filter/FilterSegment'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import useUserInfo from '@/composables/useUserInfo'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
  components: {
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    FilterDistributor,
    FilterBrand,
    FilterSegment,
    DownloadExcel,
  },
  setup() {
    const errorMessage = ref(null)
    const { isDistributor, isHelpdesk, isAm, vendor_id, regions, areas } = useUserInfo()
    const state = reactive({
      columns: [
        {
          title: 'NO',
          slots: { customRender: 'no' },
        },
        {
          title: 'Tanggal Transaksi',
          dataIndex: 'tanggal',
        },
        {
          title: 'ID Toko',
          dataIndex: 'customer_code',
        },
        {
          title: 'Nama Toko',
          dataIndex: 'customer_name',
        },
        {
          title: 'Alamat Toko',
          dataIndex: 'customer_address',
        },
        {
          title: 'Kabupaten Toko',
          dataIndex: 'customer_kabupaten',
        },
        {
          title: 'Provinsi Toko',
          dataIndex: 'customer_propinsi',
        },
        {
          title: 'Regional Toko',
          dataIndex: 'customer_regional',
        },
        {
          title: 'Area Toko',
          dataIndex: 'customer_area',
        },

        {
          title: 'Brands',
          dataIndex: 'brand_name',
        },
        {
          title: 'Kode Produk',
          dataIndex: 'product_code',
        },
        {
          title: 'Nama Produk',
          dataIndex: 'product_name',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
          slots: { customRender: 'nilai' },
        },
        {
          title: 'Zak Quantity ',
          dataIndex: 'quantity_zak',
        },
        {
          title: 'UOM 1',
          dataIndex: 'uom1',
        },
        {
          title: 'TON Quantity ',
          dataIndex: 'quantity_ton',
        },
        {
          title: 'UOM 2',
          dataIndex: 'uom2',
        },
        {
          title: 'No Transaksi',
          dataIndex: 'transaksi_code',
        },

        {
          title: 'Kode Distributor',
          dataIndex: 'distributor_code',
        },
        {
          title: 'Nama Distributor',
          dataIndex: 'distributor_name',
        },
        {
          title: 'Kode Gudang ',
          dataIndex: 'warehouse_code',
        },
        {
          title: 'Nama Gudang ',
          dataIndex: 'warehouse_name',
        },
        {
          title: 'Regional Gudang',
          dataIndex: 'regional_name',
        },
        {
          title: 'Provinsi Gudang',
          dataIndex: 'warehouse_propinsi',
        },
        {
          title: 'Area Gudang',
          dataIndex: 'warehouse_area',
        },
        {
          title: 'Kabupaten Gudang',
          dataIndex: 'warehouse_kabupaten',
        },
        {
          title: 'Tipe Customer',
          dataIndex: 'type_name',
        },
        {
          title: 'Cluster',
          dataIndex: 'cluster_name',
        },
        {
          title: 'SSM',
          dataIndex: 'user_ssm',
        },
        {
          title: 'ASM',
          dataIndex: 'user_sm',
        },
        {
          title: 'TSO',
          dataIndex: 'user_am',
        },
        {
          title: 'Source System',
          slots: { customRender: 'erp_name' },
        },
        {
          title: 'Created_at_Dist',
          dataIndex: 'created_at_dist',
        },
      ],
      endpoint: {
        current: '/api/report/detail-trx-toko-v2',
      },
      data: [],
      meta: {
        per_page: 10,
        page: 1,
        total: 0,
      },
      isFetching: false,
      params: extractQueryParams({
        q: '',
        region: [],
        area: [],
        provinsi: [],
        kabupaten: [],
        brand: [],
        distributor: [],
        type: [],
        start_date: moment(new Date()).startOf('month'),
        end_date: moment(new Date()),
        page: 1,
        'per-page': 10,
      }),
    })

    const handleTableChange = (page, filters, sorter) => {
      state.params.page = page.current
      state.params["per-page"] = page.pageSize

      fetchData()
    }

    const disabledStartDate = (current) => {
      return current && current >= moment(state.params.end_date)
    }

    const disabledEndDate = (current) => {
      return current && moment(state.params.start_date) >= current
    }

    const queryParams = () => {
      let params = state.params

      if (state.params.start_date) {
          state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
      }

      if (state.params.end_date) {
          state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
      }

      return updateUrlWithQuery(params)
    }

    const fetchData = async () => {
      state.isFetching = true
      await apiClient
        .get(state.endpoint.current, {
          params: queryParams(),
        })
        .then(({ data }) => {
          const { items, _meta } = data
          items.forEach((item, i) => {
            item.key = i
          })

          state.data = items
          state.meta.page = _meta.currentPage
          state.meta.per_page = _meta.perPage
          state.meta.total = _meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      // validation roles default params
      if (isDistributor.value) {
        state.params.distributor.push(vendor_id.value)
      }
      if (isHelpdesk.value) {
        regions.value.forEach(item => {
          state.params.region.push(item.id)
        })
      }
      if (isAm.value) {
        areas.value.forEach(item => {
          state.params.area.push(item.id)
        })
      }
      
      fetchData()
    })

    return {
      state,
      fetchData,
      queryParams,
      errorMessage,
      handleTableChange,
      disabledStartDate,
      disabledEndDate,
    }
  },
})
</script>
